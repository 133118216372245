import React, { useContext, useEffect, useState } from "react";
import { Container } from "reactstrap";
import { Buttons } from "../Utils";
import img1 from "../Assets/Group 42924.png";
import moment from "moment";
import { GlobalState } from "../Data/Context";
import { useValidation } from "../Data/useFetch";
import { BottomTab } from "./LoadMore";
import { ModalComponents } from "./DefaultHeader";
import { BiCheck, BiDotsHorizontalRounded } from "react-icons/bi";
import { HiThumbDown } from "react-icons/hi";
import { MainPaginate } from "./Transactions";
import { NetworkList, NetworkList2 } from "./Products/airtime";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { BiCopy } from "react-icons/bi";
import { useParams } from "react-router-dom";
import { ExportButtons2 } from "../Utils/Export Functions/ExportButtons";
import { NetworkLoader } from "../Utils/NetworkLoader";

const MainConvert = () => {
  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("airtime converter");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container>
        <ConvertTop />
        <ConverterHistory />
      </Container>
    </div>
  );
};

export default MainConvert;

const ConvertTop = () => {
  const {
    converterServices,
    // network,
    converter,
    usecase,
    returnErrors,
    nairaSignNeutral,
    numberWithCommas,
  } = useContext(GlobalState);
  let init = {
      account_number: "",
      account_name: "",
      bank_name: "",
      bank_code: "",
      reference: "",
      amount: "",
      network: "",
      send_to: "",
      credit_mode: "bank",
    },
    [returnValue, setReturnValue] = useState(""),
    [loading, setLoading] = useState(false),
    [newState, setNewState] = useState(false),
    [submit, setSubmit] = useState(false),
    [state, setState] = useState(init),
    [networkLoading, setNetworkLoading] = useState(false),
    textChange =
      (name) =>
      ({ target: { value } }) => {
        setState({ ...state, [name]: value });
      },
    handleSubmitCard = async (e) => {
      if (e) e.preventDefault();
      if (!state?.reference)
        return returnErrors({
          error: [
            {
              msg: "Please provide phone number the airtime was sent from",
              param: "reference",
            },
          ],
        });
      if (state?.credit_mode === "bank")
        if (
          !state?.bank_code &&
          !state?.bank_name &&
          !state?.account_name &&
          !state?.account_number
        )
          return returnErrors({
            error: [
              {
                msg: "Please provide bank name and account number",
                param: "bank",
              },
            ],
          });
      if (Number(state?.amount) < Number(usecase?.usecase?.airtimeToCashMini))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be less than NGN ${numberWithCommas(
                Number(usecase?.usecase?.airtimeToCashMini)
              )}`,
              param: "amount",
            },
          ],
        });
      if (Number(state?.amount) > Number(usecase?.usecase?.airtimeToCashMax))
        return returnErrors({
          error: [
            {
              msg: `Amount cannot be more than NGN ${numberWithCommas(
                Number(usecase?.usecase?.airtimeToCashMax)
              )}`,
              param: "amount",
            },
          ],
        });
      setLoading(true);
      await converterServices("post", "converter", state);
      setLoading(false);
      setSubmit(true);
    },
    { validateLoading, handleFetch } = useValidation(
      "banks",
      state,
      setNewState
    );

  useEffect(() => {
    if (state?.account_number?.length === 10 && state?.bank_code) handleFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.account_number, state?.bank_code]);

  useEffect(() => {
    if (state?.bank_code) {
      converter?.banks?.map(
        (item) =>
          item?.code === state?.bank_code &&
          setState({ ...state, bank_name: item?.name })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.bank_code, converter]);

  useEffect(() => {
    if (newState) {
      setState({
        ...state,
        account_name: newState?.data?.account_name,
        account_number: newState?.data?.account_number,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newState]);
  // console.log({newState});
  useEffect(() => {
    if (submit && converter?.isAdded) {
      setSubmit(false);
      setState(init);
      setReturnValue("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [converter, submit]);

  useEffect(() => {
    if (state?.amount) {
      let commission = 80;
      let sendTO = converter?.numbers?.find(
        (item) => item?.network?.toLowerCase() === state?.network?.toLowerCase()
      );
      if (sendTO) commission = sendTO?.percentage;

      setReturnValue(
        Number(Number(state?.amount) * (commission / 100)).toFixed(2)
      );
    }
  }, [state?.amount, converter?.numbers, state?.network]);

  useEffect(() => {
    if (state?.network) {
      let sendTO = converter?.numbers?.find(
        (item) => item?.network?.toLowerCase() === state?.network?.toLowerCase()
      );
      if (sendTO) setState({ ...state, send_to: sendTO?.telephone });
      else setState({ ...state, send_to: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.network, converter?.numbers]);
  // console.log({ state, numbers: converter?.numbers });
  return (
    <>
      <section className="row mx-0">
        <form
          onSubmit={handleSubmitCard}
          className="mt-4 col-md-7 row mx-0 g-3 g-md-5"
        >
          <div>
            <p className="Lexend fw-bold">
              Kindly be advised that Airtime to Cash conversion is a process
              that requires verification and is not an instant payment. We
              kindly request your patience as we work to complete the
              verification process.
            </p>
          </div>
          <div className="mb-3 col-md-6 tw-flex tw-flex-col tw-gap-2">
            <label className="text-capitalize" htmlFor="name">
              Phone number
            </label>
            <NumericFormat
              className="form-control py-3 py-md-4 bg-transparent rounded20"
              value={state.reference}
              allowLeadingZeros={true}
              allowNegative={false}
              displayType="input"
              name="phone"
              onValueChange={(val) =>
                setState({ ...state, reference: val?.value })
              }
              decimalScale={0}
              maxLength={11}
              inputMode="tel"
              renderText={(value, props) => <span {...props}>{value}</span>}
            />
            {networkLoading && <NetworkLoader />}
          </div>

          <NetworkList
            state={state?.network}
            setState={(i) => {
              setState({ ...state, network: i });
            }}
            phoneNumber={state?.reference}
            setNetworkLoading={setNetworkLoading}
            converter
          />

          {state?.network && (
            <div className="mb-3 col-md-6">
              <label className="text-capitalize" htmlFor="name">
                Number to send to
              </label>
              <input
                type="text"
                name="reference"
                className="form-control py-3 py-md-4 bg-transparent rounded20"
                value={state?.send_to}
                readOnly
              />
            </div>
          )}
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="network">
              Credit mode
            </label>
            <select
              className="form-control py-3 py-md-4 bg-transparent text-capitalize rounded20 form-select"
              name="credit_mode"
              placeholder="Credit mode"
              value={state?.credit_mode}
              onChange={textChange("credit_mode")}
              readOnly={validateLoading}
              id="credit_mode"
            >
              <option value="">select credit mode</option>
              <option value={"bank"}>My bank account</option>
              <option value={"wallet"}>My wallet account</option>
            </select>
          </div>

          {state?.credit_mode === "bank" && (
            <>
              <div className="mb-3 col-md-6">
                <label className="text-capitalize" htmlFor="bank_code">
                  Bank Name
                </label>
                <select
                  className="form-control py-3 py-md-4 bg-transparent text-capitalize form-select"
                  name="bank_code rounded20"
                  placeholder="Account Bank"
                  value={state?.bank_code}
                  onChange={textChange("bank_code")}
                  readOnly={validateLoading}
                  id="bank_code"
                >
                  <option value="">select bank</option>
                  {converter?.banks?.map((item, i) => (
                    <option value={item?.code} key={i}>
                      {item?.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mb-3 col-md-6">
                <label className="text-capitalize" htmlFor="name">
                  Account Number
                </label>
                {/* <input
									type="number"
									className="form-control py-3 py-md-4 bg-transparent rounded20"
									required
									name="account_number"
									readOnly={validateLoading}
									value={state?.account_number}
									onChange={textChange("account_number")}
								/> */}
                <NumericFormat
                  className="form-control py-3 py-md-4 bg-transparent rounded20"
                  value={state.account_number}
                  allowLeadingZeros={true}
                  allowNegative={false}
                  displayType="input"
                  name="account_number"
                  onValueChange={(val) =>
                    setState({ ...state, account_number: val?.value })
                  }
                  maxLength={10}
                  decimalScale={0}
                  inputMode="tel"
                  renderText={(value, props) => <span {...props}>{value}</span>}
                />
              </div>
              {state?.account_name && state?.account_number?.length === 10 && (
                <div className="mb-3 col-md-6">
                  <label className="text-capitalize" htmlFor="name">
                    Account name
                  </label>
                  <input
                    type="text"
                    name="account_name"
                    readOnly
                    className="form-control py-3 py-md-4 bg-transparent rounded20"
                    value={state?.account_name}
                    onChange={textChange("account_name")}
                  />
                </div>
              )}
            </>
          )}
          <div className="mb-3 col-md-6">
            <label className="text-capitalize" htmlFor="name">
              Amount
            </label>
            {/* <input
							type="number"
							required
							name="amount"
							className="form-control py-3 py-md-4 bg-transparent rounded20"
							value={state?.amount}
							onChange={textChange("amount")}
							/> */}
            <NumericFormat
              className="form-control py-3 py-md-4 bg-transparent rounded20"
              prefix={`${nairaSignNeutral} `}
              value={state.amount}
              placeholder="500"
              displayType="input"
              thousandSeparator={true}
              onValueChange={(val) =>
                setState({ ...state, amount: val?.floatValue })
              }
              min={0}
              inputMode="decimal"
              renderText={(value, props) => <span {...props}>{value}</span>}
              allowNegative={false}
            />
          </div>
          {state?.amount && (
            <div className="mb-3 col-md-6">
              <label className="text-capitalize" htmlFor="name">
                Return Amount
              </label>
              {/* <input
								type="number"
								name="amount"
								readOnly
								className="form-control py-3 py-md-4 bg-transparent rounded20"
								value={returnValue}
								onChange={textChange("returnValue")}
							/> */}
              <NumericFormat
                prefix={`${nairaSignNeutral} `}
                className="form-control py-3"
                value={returnValue}
                placeholder="500"
                displayType="input"
                readOnly
                thousandSeparator={true}
                onValueChange={(val) => setReturnValue(val?.floatValue)}
                min={0}
                inputMode="decimal"
                renderText={(value, props) => <span {...props}>{value}</span>}
                allowNegative={false}
              />
            </div>
          )}
          <div>
            <Buttons
              title={"convert"}
              css="btn-primary1 text-capitalize py-3 w-50 my-4"
              width={"w-50"}
              style={{ borderRadius: "30px" }}
              loading={loading}
              onClick={handleSubmitCard}
            />
          </div>
        </form>
        <div className="col-lg-5 h-100 my-auto d-none d-md-flex">
          <img src={img1} alt="Banner" className="img-fluid mx-auto" />
        </div>
      </section>
    </>
  );
};

const ConverterHistory = () => {
  const {
    converter,
    numberWithCommas,
    converterServices,
    auth,
    nairaSign,
    getReload,
  } = useContext(GlobalState);
  let [state, setState] = useState(null),
    [isUpdate, setIsUpdate] = useState(false),
    [isView, setIsView] = useState(false),
    [isDecline, setIsDecline] = useState(false),
    init = { reason: "" },
    [data, setData] = useState(init),
    [submit, setSubmit] = useState(false),
    [loading2, setLoading2] = useState(false),
    [search, setSearch] = useState("");

  useEffect(() => {
    if (converter.isFound) {
      setState(converter.mainSearch);
    } else setState(converter?.airtime);
  }, [converter?.airtime, converter?.mainSearch, converter?.isFound]);

  useEffect(() => {
    converterServices("get", "converter");
    converterServices("get", "banks");
    converterServices("get", "converter-number");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await converterServices("get", "converter", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await converterServices("get", "converter", {
  //     page: search
  //       ? Number(converter?.search_paginate?.nextPage)
  //       : Number(converter?.paginate?.nextPage),
  //     // limit: search
  //     // 	? Number(
  //     // 			converter?.search_paginate?.nextPage * converter?.paginate?.limit
  //     // 	  )
  //     // 	: Number(converter?.paginate?.nextPage * converter?.paginate?.limit),
  //     search,
  //   });
  //   setLoading(false);
  // };

  let clearAll = () => {
    setIsDecline(false);
    setIsUpdate(false);
    setSubmit(false);
    setData(init);
  };
  useEffect(() => {
    if (submit && converter?.isUpdated) {
      clearAll();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submit, converter?.isAddedNumber]);

  let handleSubmit = (type, method) => async (e) => {
    e?.preventDefault();
    setLoading2(true);
    await converterServices(
      method,
      type,
      data,
      isUpdate ? isUpdate?._id : isDecline ? isDecline?._id : ""
    );
    setLoading2(false);
    setSubmit(true);
  };

  // let [range, setRange] = useState(10);
  const params = useParams();

  const [pageCount, setPageCount] = useState(
    converter?.paginate
      ? Math.ceil(converter?.paginate?.total / converter?.paginate?.limit)
      : 0
  );

  useEffect(() => {
    if (converter?.search_paginate) {
      setPageCount(
        Math.ceil(converter?.paginate?.total / converter?.paginate?.limit)
      );
    } else {
      Math.ceil(converter?.paginate?.total / converter?.paginate?.limit);
    }
  }, [converter?.search_paginate, converter?.paginate]);

  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + range;

  if (!state) return;

  // const currentItems = state.slice(itemOffset, endOffset);
  const currentItems = state;
  // const pageCount = Math.ceil(state.length / range);

  const handlePageClick = async (event) => {
    // const newOffset = (event.selected * range) % state.length;
    // setItemOffset(newOffset);

    await converterServices("get", "converter", {
      page: Number(event?.selected + 1),
      search,
    });
  };

  const headers = [
    { title: "S/N", field: "serialNumber" },
    { title: "Reference", field: "reference" },
    { title: "Amount", field: "amount" },
    { title: "Network", field: "network" },
    { title: "Date & Time", field: "createdAt" },
    { title: "Status", field: "status" },
  ];

  const tableData = currentItems.map((item, index) => ({
    serialNumber: item?.item_id ? `${item?.item_id}` : "",
    reference: item?.reference ? `${item?.reference}` : "",
    amount: item?.amount ? `₦ ${numberWithCommas(item?.amount)}` : "",
    network: item?.network ? `${item?.network?.toUpperCase()}` : "",
    createdAt: moment(item?.createdAt).format("DD/MM/YYYY hh:mm A"),
    status: item?.statusText ? `${item?.statusText}` : "",
  }));

  return (
    <>
      <div className="py-5">
        <h3 className="Lexend text-capitalize mb-2 fontReduceBig">
          {" "}
          conversion history
        </h3>
        <div className="w-50 w50">
          <input
            type="search"
            name="search"
            id="Search"
            className="form-control w-100 py-3 borderColor2"
            placeholder="Type here to search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="tw-flex tw-justify-between tw-items-center tw-mb-2">
          <div className="tw-flex-1">
            {/* <MainRanger range={range} setRange={setRange} /> */}
          </div>
          <ExportButtons2
            headers={headers}
            data={tableData}
            fileName={`${params?.page} ${params?.id ? `(${params?.id})` : ""}`}
          />
        </div>
        <div className="bland row mx-0 p-3 text-capitalize">
          <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
            ID
          </div>
          <div className="col textTrunc fontReduce fw-bold Lexend">
            Reference
          </div>
          {/* <div className="col textTrunc">number</div> */}
          <div className="col textTrunc fontReduce fw-bold Lexend">amount</div>
          <div className="col textTrunc fontReduce fw-bold Lexend">
            network{" "}
          </div>
          <div className="col textTrunc fontReduce fw-bold Lexend d-none d-md-flex">
            date&time
          </div>
          <div className="col textTrunc fontReduce fw-bold Lexend">status</div>
          <div className="col textTrunc fontReduce fw-bold Lexend">action</div>
        </div>
        <div className="bg-white row mx-0">
          {currentItems?.map((item, index) => (
            <div key={index} className="row mx-0 p-3">
              <div className="col d-none d-md-flex textTrunc my-auto fontReduce2">
                {item?.item_id}
              </div>
              <div className="col textTrunc my-auto fontReduce2">
                {item?.reference}
              </div>
              <div className="col textTrunc my-auto fontReduce2">
                {nairaSign} {numberWithCommas(item?.amount)}
              </div>
              {/* <div className="col textTrunc my-auto">{item?.telephone}</div> */}
              <div className="col textTrunc my-auto fontReduce2">
                {/* {item?.network} */}
                <NetworkList2 state={item?.network?.toUpperCase()} />
              </div>
              <div className="col d-none d-md-flex textTrunc my-auto fontReduce2">
                {moment(item?.createdAt).format("DD/MM/YYYY hh:mm A")}
              </div>
              <div
                className={`col textTrunc my-auto text-capitalize fontReduce2 ${
                  item?.status
                    ? "text-success"
                    : item?.statusText === "declined"
                    ? "text-danger"
                    : ""
                }`}
              >
                {item?.statusText}
              </div>
              <div className="col textTrunc my-auto btn-group fontReduce2 w-100">
                <button
                  onClick={() => setIsView(item)}
                  className="btn  btn-primary1 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                >
                  <BiDotsHorizontalRounded />
                </button>
                {auth?.user?.privilege === "agent" && (
                  <>
                    <button
                      onClick={() => setIsUpdate(item)}
                      className="btn  btn-success2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                    >
                      <BiCheck />
                    </button>
                    <button
                      onClick={() => setIsDecline(item)}
                      className="btn  btn-danger2 text-capitalize p-1 p-md-2 w-100 fontReduce2"
                    >
                      <HiThumbDown />
                    </button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        <MainPaginate handlePageClick={handlePageClick} pageCount={pageCount} />
        <BottomTab state={state} paginate={converter?.paginate} />
        {/* <LoadMore
          next={converter?.paginate?.next}
          handleLoadMore={handleLoadMore}
          loading={loading}
        /> */}
      </div>
      <ModalComponents
        title={"Decline conversion"}
        isOpen={isDecline}
        back={() => {
          setIsDecline(false);
        }}
      >
        <div className="mb-3">
          <label className="text-capitalize" htmlFor="name">
            Reason
          </label>
          <textarea
            className="form-control py-3 py-md-4 text-capitalize"
            name="reason"
            id="reason"
            style={{
              resize: "none",
              height: "10rem",
            }}
            value={data?.reason}
            onChange={(e) =>
              setData({
                ...data,
                reason: e.target.value,
              })
            }
            placeholder="Reason for decline"
          />
        </div>
        <Buttons
          loading={loading2}
          title="decline"
          onClick={handleSubmit("converter", "post")}
          css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
          width={"w-50"}
        />
      </ModalComponents>
      <ModalComponents
        title={"Mark as done"}
        isOpen={isUpdate}
        back={() => {
          setIsUpdate(false);
        }}
      >
        <div className="downH2 d-flex">
          <div className="my-auto w-100">
            <p className="text-center">Has the transaction been completed?</p>
            <div className="d-flex w-100">
              <Buttons
                loading={loading2}
                title="confirm"
                onClick={handleSubmit("converter", "put")}
                css="btn-primary1 text-capitalize py-3 w-50 my-4 mx-auto"
                width={"w-50"}
              />
            </div>
          </div>
        </div>
      </ModalComponents>
      <ModalComponents
        title={"View full details"}
        isOpen={isView}
        back={() => {
          setIsView(false);
        }}
      >
        <div className="downH2 d-flex">
          <div className="w-100">
            <h4 className="Lexend">Conversion details</h4>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Id: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  isView?.item_id
                    ? () => {
                        navigator.clipboard.writeText(isView?.item_id).then(
                          () => {
                            toast.info("Copied", { autoClose: 2000 });
                          },
                          (err) => {
                            toast.warn(`Could not copy: ${err}`, {
                              autoClose: 2000,
                            });
                          }
                        );
                      }
                    : null
                }
              >
                {isView?.item_id}
                <BiCopy />
              </span>{" "}
            </p>
            {isView?.channel && isView?.channel !== "auth" && (
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>Channel: </span>
                <span className="fontInherit Lexend">
                  {isView?.channel}
                </span>{" "}
              </p>
            )}
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Credit Mode: </span>
              <span className="fontInherit Lexend">
                {isView?.credit_mode}
              </span>{" "}
            </p>
            {isView?.credit_mode !== "wallet" && (
              <>
                <p className="text-capitalize border-bottom d-flex justify-content-between">
                  <span>Bank name: </span>
                  <span className="fontInherit Lexend">
                    {isView?.bank_name}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between">
                  <span>Account name: </span>
                  <span className="fontInherit Lexend">
                    {isView?.account_name}
                  </span>{" "}
                </p>
                <p className="text-capitalize border-bottom d-flex justify-content-between">
                  <span>Account number: </span>
                  <span
                    className="fontInherit Lexend force-d-flex myCursor"
                    onClick={
                      isView?.account_number
                        ? () => {
                            navigator.clipboard
                              .writeText(isView?.account_number)
                              .then(
                                () => {
                                  toast.info("Copied", { autoClose: 2000 });
                                },
                                (err) => {
                                  toast.warn(`Could not copy: ${err}`, {
                                    autoClose: 2000,
                                  });
                                }
                              );
                          }
                        : null
                    }
                  >
                    {isView?.account_number}
                    <BiCopy />
                  </span>{" "}
                </p>
              </>
            )}
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Sender Number: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  isView?.reference
                    ? () => {
                        navigator.clipboard.writeText(isView?.reference).then(
                          () => {
                            toast.info("Copied", { autoClose: 2000 });
                          },
                          (err) => {
                            toast.warn(`Could not copy: ${err}`, {
                              autoClose: 2000,
                            });
                          }
                        );
                      }
                    : null
                }
              >
                {isView?.reference}
                <BiCopy />
              </span>
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Network: </span>
              <span className="fontInherit Lexend">{isView?.network}</span>{" "}
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Amount: </span>
              <span className="fontInherit Lexend">
                {nairaSign}{" "}
                {isView?.amount ? numberWithCommas(isView?.amount) : 0}
              </span>{" "}
            </p>
            <ReturnAmountView isView={isView} />
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Receiver's number: </span>
              <span
                className="fontInherit Lexend force-d-flex myCursor"
                onClick={
                  isView?.number_sent_to
                    ? () => {
                        navigator.clipboard
                          .writeText(isView?.number_sent_to)
                          .then(
                            () => {
                              toast.info("Copied", { autoClose: 2000 });
                            },
                            (err) => {
                              toast.warn(`Could not copy: ${err}`, {
                                autoClose: 2000,
                              });
                            }
                          );
                      }
                    : null
                }
              >
                {isView?.number_sent_to}
                <BiCopy />
              </span>{" "}
            </p>
            <p className="text-capitalize border-bottom d-flex justify-content-between">
              <span>Status: </span>
              <span
                className={`fontInherit Lexend ${
                  isView?.status
                    ? "text-success"
                    : isView?.statusText === "declined"
                    ? "text-danger"
                    : ""
                }`}
              >
                {isView?.statusText}
              </span>
            </p>
            {isView?.statusText === "declined" && (
              <p className="text-capitalize border-bottom d-flex justify-content-between">
                <span>Reason: </span>
                <span className="fontInherit Lexend">
                  {isView?.reason}
                </span>{" "}
              </p>
            )}
            <div className="ms-auto d-flex align-items-center">
              <button
                className="btn btn-danger-2 btn-danger2 d-block ms-auto me-2"
                onClick={() => setIsView(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ModalComponents>
    </>
  );
};

let ReturnAmountView = ({ isView }) => {
  let [re, setRe] = useState(0),
    { converter, nairaSign, numberWithCommas } = useContext(GlobalState);

  useEffect(() => {
    if (isView?.amount) {
      let commission = 80;
      let sendTO = converter?.numbers?.find(
        (item) =>
          item?.network?.toLowerCase() === isView?.network?.toLowerCase()
      );
      if (sendTO) commission = sendTO?.percentage;

      setRe(Number(Number(isView?.amount) * (commission / 100)).toFixed(2));
    }
  }, [isView?.amount, converter?.numbers, isView?.network]);

  return (
    <>
      <p className="text-capitalize border-bottom d-flex justify-content-between">
        <span>Return Amount: </span>
        <span className="fontInherit Lexend">
          {nairaSign}{" "}
          {isView?.returnAmount
            ? numberWithCommas(Number(isView?.returnAmount).toFixed())
            : re
            ? numberWithCommas(Number(re).toFixed(2))
            : 0}
        </span>{" "}
      </p>
    </>
  );
};
