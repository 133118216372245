import {
  LOGIN_USER,
  LOGIN_USER_FAIL,
  LOGOUT,
  TOKEN,
  GET_USER,
  GET_USER_LOADING,
  GET_USER_FAIL,
  GET_ERRORS_TEXT,
  REGISTER_USER,
  UPDATE_USER,
  REGISTER_USER_FAIL,
  UPDATE_USER_FAIL,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_FAIL,
  SET_SUCCESS,
} from "./ActionTypes";
import { SetAuthToken, useURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { clearErrors, returnErrors } from "../Reducer/ErrorReducer";
import {
  // getBiller,
  getCategory,
  // getGateway,
  getNetwork,
  // getProducts,
} from "./ProviderAction";
import { getSettings } from "../Reducer/SettingsReducer";
import { getUseCase } from "../Reducer/UseCaseReducer";
import {
  // getServicesHistory,
  getWalletBalance,
} from "./GeneralAction";
import // manageBanks,
//  manageWithdrawal
"./NotificationAction";

// LOGOUT
export const logoutUser = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
  try {
    axios.post(`/api/v2/user/logout`, {}, { baseURL: useURL });
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: GET_USER_FAIL });
  }
  dispatch(clearErrors());
};

// GET USER INFO
export const loadUser = () => async (dispatch) => {
  let token = localStorage.getItem(TOKEN);
  if (token) SetAuthToken(token);

  dispatch({ type: GET_USER_LOADING });
  dispatch(clearErrors());
  try {
    let res = await axios.get(`/api/v2/user`, {
      baseURL: useURL,
    });
    if (res?.data?.data?.privilege) {
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      dispatch(getWalletBalance());
      dispatch(getSettings());
      dispatch(getNetwork());
      dispatch(getUseCase());
      dispatch(getCategory());
      // dispatch(getDataToBuy());
      // dispatch(getElectricityToBuy());
      // dispatch(getEducationToBuy());
      // dispatch(getCablesPackages());
      // dispatch(getCablesTypes());

      // dispatch(getBiller());
      // dispatch(getGateway());

      // dispatch(getProducts());

      // dispatch(manageBanks("get"));
      // dispatch(manageWithdrawal("get"));

      // dispatch(getServicesHistory("all", { streamline: "day" }));
      // dispatch(getServicesHistory("all", { streamline: "month" }));
    } else {
      dispatch({ type: GET_USER_FAIL });
    }
  } catch (err) {
    if (err) console.log(err.response?.data?.error, { err });
    if (err?.response?.status === 429) toast.error(err?.response?.data);
    dispatch({ type: GET_USER_FAIL });
    dispatch({
      type: GET_ERRORS_TEXT,
      payload: err?.response?.data?.error
        ? err?.response?.data?.error?.[0]?.msg
        : err?.response?.data
        ? err?.response?.data
        : err?.message,
    });
  }
};

// LOGIN ACTION
export const loginUser = (userData) => async (dispatch) => {
  try {
    let res = await axios.post(
      `/api/v2/user/login`,
      { ...userData },
      {
        baseURL: useURL,
      }
    );
    dispatch(clearErrors());

    dispatch({
      type: LOGIN_USER,
      payload: res.data,
    });

    let token = localStorage.getItem(TOKEN);
    if (token) SetAuthToken(token);

    // dispatch(getWalletBalance());
    dispatch(getSettings());
    dispatch(getNetwork());
    dispatch(getUseCase());
    dispatch(getCategory());

    toast.success(res.data.msg, { autoClose: 5000 });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: LOGIN_USER_FAIL });
  }
};

// REGISTER ACTION
export const registerUser = (userData) => async (dispatch) => {
  dispatch(clearErrors());
  console.log({ userData });
  try {
    var res = await axios.post(
      "/api/v2/user",
      { ...userData },
      {
        baseURL: useURL,
      }
    );

    dispatch({
      type: REGISTER_USER,
      payload: res.data,
    });
    toast.success(res.data.msg, { autoClose: 5000 });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: REGISTER_USER_FAIL });
  }
};

export const updatePassword = (userData) => async (dispatch) => {
  dispatch(clearErrors());

  try {
    var res = await axios.put(
      `/api/v2/user/update-password`,
      { ...userData },
      { baseURL:  useURL }
    );

    dispatch({
      type: UPDATE_PASSWORD,
      payload: res.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: UPDATE_PASSWORD_FAIL });
  }
};

export const updateUser = (userData, type) => async (dispatch) => {
  dispatch(clearErrors());

  try {
    var avatar, res;
    if (type === "profile-image") {
      let media = await imageUpload([userData.logo]);
      avatar = media[0];
      // console.log({ avatar, media, userData });
      res = await axios.put(
        `/api/v2/user/update-avatar`,
        {
          ...userData,
          avatar,
        },
        { baseURL: useURL }
      );
    } else {
      res = await axios.put(
        `/api/v2/user`,
        { ...userData },
        { baseURL:  useURL }
      );
    }

    dispatch({
      type: UPDATE_USER,
      payload: res.data,
    });
    dispatch({ type: SET_SUCCESS, payload: res?.data?.msg });
  } catch (err) {
    if (err?.response?.status === 429 || err?.response?.status === 405)
      toast.error(err?.response?.data ? err?.response?.data : err?.message);
    console.log({ err });
    let error = err.response?.data?.error;
    if (error) {
      dispatch(returnErrors({ error, status: err?.response?.status }));
    }
    dispatch({ type: UPDATE_USER_FAIL });
  }
};

export const imageUpload = async (images) => {
  let imgArr = [];
  for (const item of images) {
    // console.log({ item });
    let post = new FormData();
    post.append(`file`, item);

    let res = await axios.post(`/api/v2/file`, post, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    const data = await res.data?.data;
    // console.log({ data });
    Array.isArray(data) ? (imgArr = [...imgArr, ...data]) : imgArr.push(data);
  }
  return imgArr;
};
