import React, { useState, useContext, useEffect, useRef } from "react";
import { Container } from "reactstrap";
import { Buttons, EmptyComponent } from "../../Utils";
import { GlobalState } from "../../Data/Context";
import { BottomTab } from "../LoadMore";
import { TransactionDetails, NewPaginate } from "../Transactions";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import { ExportButtons2 } from "../../Utils/Export Functions/ExportButtons";

const MainBiz = () => {
  let navigate = useNavigate();

  let { setStateName } = useContext(GlobalState);
  useEffect(() => {
    setStateName("biz verification history");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <Buttons
          title={"find biz"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
          width={"w-25 w25"}
          onClick={() => navigate("/products/biz/verify")}
          style={{ borderRadius: "30px" }}
        />
        <MainBizHistory />
      </Container>
    </div>
  );
};

export default MainBiz;

const MainBizHistory = () => {
  let { biz, getServicesHistory, getReload } = useContext(GlobalState);

  let [data, setData] = useState(null),
    [search, setSearch] = useState(""),
    [thisData, setThisData] = useState(null);

  useEffect(() => {
    if (biz.isFound) {
      setData(biz.mainSearch);
    } else setData(biz.biz);
  }, [biz.biz, biz.isFound, biz.mainSearch]);

  useEffect(() => {
    if (search) {
      document.getElementById("Search").addEventListener("search", () => {
        getReload();
      });
      let handleSubmit = async () => {
        if (!search) return;

        await getServicesHistory("biz", {
          search,
        });
      };
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    getReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getServicesHistory("biz");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // let handleLoadMore = async () => {
  //   setLoading(true);

  //   await getServicesHistory("biz", {
  //     page: Number(biz?.paginate?.nextPage),
  //     // limit: Number(biz?.paginate?.nextPage * biz?.paginate?.limit),
  //   });
  //   setLoading(false);
  // };

  if (!data) return;
  // console.log({ data });

  return (
    <div className="py-5">
      <div className="w-50 w50 mb-3">
        <input
          type="search"
          name="search"
          id="Search"
          className="form-control w-100 py-3 borderColor2"
          placeholder="Type here to search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <NewPaginate
        state={data}
        setState={setData}
        setThisData={setThisData}
        type={"biz"}
        criteria={{
          search,
        }}
      />
      <TransactionDetails
        thisData={thisData}
        setThisData={setThisData}
        type={"biz"}
        criteria={
          {
            // id: params?.step,
          }
        }
      />
      <BottomTab state={data} paginate={biz?.paginate} />
      {/* <LoadMore
        next={biz?.paginate?.next}
        handleLoadMore={handleLoadMore}
        loading={loading}
      /> */}
    </div>
  );
};

export const BizFindPage = () => {
  let {
    setStateName,
    biz,
    buyServices,
    returnErrors,
    auth,
    usecase,
    numberWithCommas,
  } = useContext(GlobalState);
  useEffect(() => {
    setStateName("Verify biz name");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let [state, setState] = useState(""),
    [loading, setLoading] = useState(false),
    [submit, setSubmit] = useState(false),
    handleSubmit = async (e) => {
      e?.preventDefault();
      if (!state)
        return returnErrors({
          error: [{ msg: "Business name is required", param: "name" }],
        });
      setLoading(true);
      await buyServices("biz", { name: state });
      setLoading(false);
      setSubmit(true);
    },
    navigate = useNavigate();

  const params = useParams();

  let [stateData, setStateData] = useState(null);
  useEffect(() => {
    setStateData(usecase?.usecase);
  }, [usecase?.usecase]);

  const getCommission = (type) => {
    let com;
    switch (type) {
      case "agent": {
        com = stateData?.bizAgent || stateData?.bizUser;

        return com;
      }
      case "reseller": {
        com = stateData?.bizReseller || stateData?.bizUser;

        return com;
      }
      default:
        return stateData?.bizUser || null;
    }
  };

  let ref = useRef();
  const handlePrint = useReactToPrint({
    content: () => ref.current,
    documentTitle: `${
      process.env.REACT_APP_NAME
    }-${biz?.biz?.[0]?.type?.toUpperCase()}-${moment(
      biz?.biz?.[0]?.createdAt
    ).format("dddd, L")}`,
    bodyClass: "p-2",
  });

  useEffect(() => {
    if (biz?.isAdded && submit) {
      setSubmit(false);
      setState("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [biz?.isAdded, submit]);

  const headers = [
    { title: "RC NUMBER", field: "rc_number" },
    { title: "APPROVED NAME", field: "approved_name" },
    { title: "ADDRESS", field: "address" },
    { title: "EMAIL", field: "email" },
    { title: "REGISTRATION DATE", field: "registration_date" },
    { title: "COMPANY STATUS", field: "company_status" },
  ];

  const data = biz?.biz?.[0]?.properties?.result?.map((item) => ({
    rc_number: item?.rc_number ? `${item?.rc_number}` : "",
    approved_name: item?.approved_name ? `${item?.approved_name}` : "",
    address: item?.address ? `${item?.address}` : "",
    email: item?.email ? `${item?.email}` : "",
    registration_date: moment(item?.registration_date).format(
      "DD/MM/YYYY HH:mm A"
    ),
    company_status: item?.registration_approved ? "Approved" : "Not Approved",
  }));

  return (
    <div className="bg-white aboutScreen">
      <Container className="py-5">
        <Buttons
          title={"View History"}
          css="btn-primary1 text-capitalize py-3 px-4 px-lg-5 ms-auto my-4"
          width={"w-25 w25"}
          onClick={() => navigate("/products/biz")}
          style={{ borderRadius: "30px" }}
        />
        <div className="d-flex flex-column justify-content-center">
          <form onSubmit={handleSubmit} className="w-50 w50 mx-auto">
            <p className="Lexend">
              Please verify people's business before engaging in any deals for
              just ₦
              {numberWithCommas(
                Number(getCommission(auth?.user?.privilege)).toFixed(2)
              )}
            </p>
            <p className="Lexend">
              To do so, kindly fill out the form below with the business name,
              and you will receive an instant response.
            </p>
            <p className="tw-text-[red]">
              <strong>N.B:</strong> Using fake or placeholder names (e.g.,
              "ABCDEF") for business verification leads to inaccurate results.
              We debit for such attempts. Always use the correct business name.
            </p>
            <input
              type="text"
              value={state}
              onChange={(e) => setState(e.target.value)}
              className="py-3 form-control"
              placeholder="Type business name here"
            />
            <Buttons
              title={"find biz"}
              css="btn-primary1 text-capitalize py-3 px-4 px-lg-5"
              width={"w-25 w25 mx-auto d-block mt-3"}
              onClick={handleSubmit}
              loading={loading}
              style={{ borderRadius: "30px" }}
            />
          </form>
        </div>

        <div className="py-3 py-md-5" ref={ref}>
          {biz?.isAdded && (
            <>
              <div className="tw-flex tw-justify-end tw-mb-2">
                <ExportButtons2
                  headers={headers}
                  data={data}
                  fileName={`${params?.page} ${
                    params?.id ? `(${params?.id})` : ""
                  }`}
                />
              </div>

              <div className="row mx-0 py-3 bland">
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  s/n
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
                  rc Number
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  Approved Name
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  address
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  email
                </div>
                <div className="col my-auto text-uppercase fontReduce2 fw-bold Lexend d-none d-md-flex">
                  registration Date
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  company Status
                </div>
                <div className="col textTrunc my-auto text-uppercase fontReduce2 fw-bold Lexend">
                  status
                </div>
              </div>
              {biz?.biz?.[0]?.properties?.result?.length === 0 ? (
                <EmptyComponent
                  subtitle={"Business vierification result empty"}
                />
              ) : (
                biz?.biz?.[0]?.properties?.result?.map((item, i) => (
                  <div
                    className="row mx-0 bland2 border-bottom page-break"
                    key={i}
                  >
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {i + 1}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.rc_number}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.approved_name}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4 textTrunc4">
                      {item?.address}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.email}
                    </div>
                    <div className="col my-auto d-none d-md-flex textTrunc py-3 py-md-4">
                      {moment(item?.registration_date).format(
                        "DD/MM/YYYY HH:mm A"
                      )}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.registration_approved
                        ? "Approved"
                        : "Not Approved"}
                    </div>
                    <div className="col my-auto fontReduce2 textTrunc py-3 py-md-4">
                      {item?.active ? "Active" : "Not Active"}
                    </div>
                  </div>
                ))
              )}
            </>
          )}
        </div>
        {biz?.isAdded && biz?.biz?.[0]?.properties?.result?.length > 0 && (
          <div className="ms-auto d-flex align-items-center py-2">
            <button
              className="btn btn-primary1 d-block ms-auto"
              onClick={handlePrint}
            >
              Print List
            </button>
          </div>
        )}
      </Container>
    </div>
  );
};
