import axios from "axios";

export const SetAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
    axios.defaults.headers.common["frontend-source"] = "web";
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete axios.defaults.headers.common["frontend-source"];
  }
};

const env = process.env.REACT_APP_ENV;

export const useURL =
  env === "PROD"
    ? process.env.REACT_APP_PROD_BASE_URL
    : env === "TEST"
    ? process.env.REACT_APP_TEST_BASE_URL
    : process.env.REACT_APP_DEV_BASE_URL;

export const SetDefaultHeaders = () => {
  axios.defaults.baseURL = useURL;
  axios.defaults.headers.common["frontend-source"] = "web";
};
