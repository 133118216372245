import React, { useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./Styles/sweetalert2.min.css";
import {
  Sidebar,
  DefaultHeader,
  SideHeader,
  // ModalComponents,
} from "./Components";
import ReactDOMServer from "react-dom/server";
import { GlobalState } from "./Data/Context";
import PageRender from "./PageRender";
import Home2 from "./Pages/home";
// import gif from "./Assets/icons8-ok.gif";
// import gif2 from "./Assets/icons8-cancel.gif";
// import pendingGif from "./Assets/icons8-pending.gif";
import { Fragment } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Swal from "sweetalert2";

// import HomeOne from "./Screens/home-one";
import HomeTwo from "./Screens/home-two";
import BackToTopButton from "./Components/backtotop";
import withReactContent from "sweetalert2-react-content";

const Routers = () => {
  const {
      auth,
      success,
      restoreMsg,
      errors,
      clearErrors,
      settings,
      logoutUser,
    } = useContext(GlobalState),
    navigate = useNavigate();
  const mySwal = withReactContent(Swal);
  useEffect(() => {
    if (errors?.error?.error?.length > 0) {
      const errorHtml = ReactDOMServer.renderToStaticMarkup(
        <>
          {errors.error.error.map((err, i) => (
            <p key={i} className="fw-bold Lexend text-center w-100">
              <span className="fontInherit me-2">
                {errors.error.error.length > 1 && <>{i + 1}.</>}
              </span>{" "}
              {err?.msg}
            </p>
          ))}
        </>
      );

      mySwal
        .fire({
          title: `Hi ${auth?.user?.firstName}`,
          icon: "error",
          html: errorHtml, // Use the HTML string
          confirmButtonText: "Try again",
          confirmButtonColor: "#2986E4",
        })
        .then((result) => {
          if (result?.isConfirmed) {
            if (
              errors?.error?.error?.[0]?.msg
                ?.toLowerCase()
                ?.includes("insufficient wallet fund")
            ) {
              navigate("/wallets");
            }
            if (
              errors?.error?.error?.[0]?.msg
                ?.toLowerCase()
                ?.includes("session timeout, please login again")
            ) {
              handleLogOut();
            }
            if (
              errors?.error?.error?.[0]?.msg
                ?.toLowerCase()
                ?.includes("multiple logins detected. re-login to continue.")
            ) {
              handleLogOut();
            }
            clearErrors();
          }
        })
        .finally();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);
  useEffect(() => {
    if (success?.msg) {
      mySwal
        .fire({
          title: `Hi ${auth?.user?.firstName}`,
          text: success?.msg,
          icon: "success",
          confirmButtonText: "Ok",
          confirmButtonColor: "#2986E4",
        })
        .then((result) => {
          if (result?.isConfirmed) {
            restoreMsg();
            console.log(result);
          }
        })
        .finally();
    }

    if (success?.msg?.includes("pending")) {
      mySwal
        .fire({
          title: `Hi ${auth?.user?.firstName}`,
          text: success?.msg,
          icon: "warning",
          customClass: {
            icon: "custom-info-icon", // Custom class for the icon
          },
          confirmButtonText: "Ok",
          confirmButtonColor: "#2986E4",
        })
        .then((result) => {
          if (result?.isConfirmed) {
            restoreMsg();
            console.log(result);
          }
        })
        .finally();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);
  let handleLogOut = async (e) => {
    e?.preventDefault();
    logoutUser();
    navigate("/");
  };
  // handleCloseError = () => {
  //   clearErrors();
  //   if (
  //     errors?.error?.error?.[0]?.msg
  //       ?.toLowerCase()
  //       ?.includes("insufficient wallet fund")
  //   ) {
  //     navigate("/wallets");
  //   }
  //   if (
  //     errors?.error?.error?.[0]?.msg
  //       ?.toLowerCase()
  //       ?.includes("session timeout, please login again")
  //   ) {
  //     handleLogOut();
  //   }
  //   if (
  //     errors?.error?.error?.[0]?.msg
  //       ?.toLowerCase()
  //       ?.includes("multiple logins detected. re-login to continue.")
  //   ) {
  //     handleLogOut();
  //   }
  // };

  return (
    <>
      <ToastContainer autoClose={false} />
      <BackToTopButton />
      {auth?.user ? (
        <>
          <Sidebar />
          <SideHeader noLogo />
        </>
      ) : (
        <></>
      )}
      <div className={auth?.user ? "home" : ""}>
        {auth?.user ? <DefaultHeader /> : <></>}
        <Routes>
          <Route path="/" element={auth?.user ? <Home2 /> : <HomeTwo />} />
          <Route path="/:page" element={<PageRender />} />
          <Route path="/:page/:id" element={<PageRender />} />
          <Route path="/:page/:id/:step" element={<PageRender />} />
        </Routes>
      </div>
      {/* {success?.msg &&
        Swal?.fire({
          title: "",
          text: success?.msg,
          icon: "success",
          backdrop: false,
          buttonsStyling: false,
          allowOutsideClick: true,
        })} */}
      {/* <ModalComponents
        isOpen={success?.msg ? true : false}
        title="Success"
        size={"sm"}
        success="text-success text-succcess2"
        borderNone={"borderNone"}
        toggle={() => restoreMsg()}
      >
        <div className="downH2 d-flex flex-column">
          <div className="mx-auto">
            <img src={gif} alt="Gif" className="img-fluid" />
          </div>
          <p className="fw-bold Lexend text-center w-100">{success?.msg}</p>
          <button
            onClick={() => restoreMsg()}
            className="btn btn-success2 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5 mt-auto"
          >
            close
          </button>
        </div>
      </ModalComponents> */}
      {/* <div>
        {" "}
        {errors?.error?.error?.length > 0 &&
          withReactContent(Swal).fire({
            title: "Error",
            icon: "error",
            text: errors?.error?.error[0]?.msg,
            customClass: {
              popup: "my-swal",
            },
            heightAuto: false,
            backdrop: "rgba(0, 0, 0, 0.4)",
          })}
      </div> */}
      {/* <ModalComponents
        isOpen={errors?.error?.error?.length > 0}
        title="Error"
        size={"sm"}
        success="text-danger text-danger2"
        borderNone={"borderNone"}
        toggle={handleCloseError}
      >
        <div className="downH2 d-flex flex-column">
          <div className="mx-auto mb-3">
            <img src={gif2} alt="Gif" className="img-fluid" />
          </div>
          {errors?.error?.error?.map((item, i) => (
            <p key={i} className="fw-bold Lexend text-center w-100">
              <span className="fontInherit me-2">
                {errors?.error?.error?.length > 1 && <>{i + 1}.</>}
              </span>{" "}
              {item?.msg}
            </p>
          ))}
          <button
            onClick={handleCloseError}
            className="btn btn-primary1 py-2 py-md-3 text-capitalize mx-auto my-3 px-3 px-md-5 mt-auto"
          >
            close
          </button>
        </div>
      </ModalComponents> */}
      <div className="position-relative">
        <div
          className="position-absolute"
          style={{
            bottom: "2rem",
            right: "2rem",
          }}
        >
          {process.env.REACT_APP_AGENT_TWITTER && (
            <a
              style={{
                height: "3rem",
                width: "3rem",
                background: "#3199b7",
              }}
              className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
              href={process.env.REACT_APP_AGENT_TWITTER}
              target={"_blank"}
              title="Contact admin through Twitter"
              rel={"noreferrer"}
            >
              <BsTwitter size={24} />
            </a>
          )}
          {process.env.REACT_APP_AGENT_INSTAGRAM && (
            <a
              style={{
                height: "3rem",
                width: "3rem",
                background: "#ff476b",
              }}
              className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
              href={process.env.REACT_APP_AGENT_INSTAGRAM}
              target={"_blank"}
              title="Contact admin through Instagram"
              rel={"noreferrer"}
            >
              <BsInstagram size={24} />
            </a>
          )}
          {process.env.REACT_APP_AGENT_FACEBOOK && (
            <a
              style={{
                height: "3rem",
                width: "3rem",
                background: "#1a10c5",
              }}
              className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
              href={process.env.REACT_APP_AGENT_FACEBOOK}
              target={"_blank"}
              title="Contact admin through Facebook"
              rel={"noreferrer"}
            >
              <FaFacebookF size={24} />
            </a>
          )}
          {settings?.settings?.accountWhatsApp ||
          process.env.REACT_APP_AGENT_WHATSAPP_GROUP ? (
            <a
              style={{
                height: "3rem",
                width: "3rem",
                background: "#20c997",
              }}
              className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
              href={
                settings?.settings?.accountWhatsApp ||
                process.env.REACT_APP_AGENT_WHATSAPP_GROUP
              }
              target={"_blank"}
              title="Join WhatsApp Group"
              rel={"noreferrer"}
            >
              <FaWhatsapp size={24} />
            </a>
          ) : null}
          {process.env.REACT_APP_AGENT_WHATSAPP && (
            <a
              style={{
                height: "3rem",
                width: "3rem",
                background: "#20c997",
              }}
              className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
              href={process.env.REACT_APP_AGENT_WHATSAPP}
              target={"_blank"}
              title="Contact admin through WhatsApp"
              rel={"noreferrer"}
            >
              <FaWhatsapp size={24} />
            </a>
          )}
          {settings?.settings?.accountEmail ||
          process.env.REACT_APP_AGENT_EMAIL ? (
            <a
              style={{
                height: "3rem",
                width: "3rem",
                background: "#d63384",
              }}
              title="Contact admin through email"
              className="d-flex justify-content-center align-items-center bg-select-2 my-2  rounded-circle text-decoration-none text-capitalize text-white"
              href={`mailto:${
                settings?.settings?.accountEmail ||
                process.env.REACT_APP_AGENT_EMAIL
              }`}
              target={"_blank"}
              rel={"noreferrer"}
            >
              <HiOutlineEnvelope size={24} />
            </a>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Routers;
